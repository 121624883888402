<template>
    <div class="page-wrap">
        <div v-if="showDel">
            <div class="top-card">
                <div class="message-alert message-alert-back">
                    <div class="alert-title">消息提示</div>
                    <div class="alert-content">
                        <p>
                            超过<span>30天</span>未签署的合同将会进入<span>合同回收站</span>，可去合同回收站<span>恢复合同</span>。
                            回收站内<span>超过{{ delDay }}天</span>没处理，则会<span>删除</span>该合同。
                        </p>
                        <p>从回收站恢复的合同<span>超过7天</span>没签署，则会<span>再次进入回收站</span>，<span>超过{{ delDay
                                }}天</span>没处理，则会<span>删除</span>该合同。
                        </p>
                    </div>
                </div>
            </div>
            <div class="top-card">
                <div class="l-box" :style="userInfo.pid == 0 ? 'width: 75%;' : 'width: 100%;'">
                    <div class="nav_title">合同管理</div>
                    <div class="input-group">
                        <div class="flex f-wrap">
                            <div class="wrap flex">
                                <div class="label">检索：</div>
                                <div class="r-find">
                                    <Input v-model="storePrams.contract_id" placeholder="请输入合同名称、合同ID"
                                        style="width: 240px" />
                                </div>
                            </div>
                            <div class="wrap flex">
                                <div class="label">签署方检索：</div>
                                <div class="r-find">
                                    <Input v-model="storePrams.search_part" placeholder="请输入签署方姓名、手机号、金额、公司名称"
                                        style="width: 300px" />
                                </div>
                            </div>

                            <div class="wrap flex" v-if='userInfo.role == 2'>
                                <div class="label">管理员：</div>
                                <div class="r-find">
                                    <Select v-model="storePrams.user_id" default-label="未签署"
                                        @on-change='chooseAdministrators' style="width:200px;" clearable filterable
                                        @on-clear="clearAdministrators">
                                        <Option v-for="item in administratorsList" :value="item.id" :key="item.value">{{
                                            item.real_name }}</Option>
                                    </Select>
                                </div>
                            </div>
                            <!-- 
                            </div>
                            <div class="flex f-wrap"> -->
                            <div class="wrap flex">
                                <div class="label">状态：</div>
                                <div class="r-find">
                                    <Select v-model="storePrams.status" default-label="未签署"
                                        style="width:200px;margin-left: 12px" clearable>
                                        <Option v-for="item in selectList" :value="item.value" :key="item.value">{{
                                            item.label }}</Option>
                                    </Select>
                                </div>
                            </div>
                            <div class="wrap flex">
                                <div class="label">创建日期：</div>
                                <div class="r-find">
                                    <DatePicker v-model="timeVal" @on-change="changeTime" type="daterange"
                                        placement="bottom-end" placeholder="点击选择时间" style="width: 250px">
                                    </DatePicker>
                                </div>
                            </div>
                            <div class="wrap flex">
                                <div class="label">签署日期：</div>
                                <div class="r-find">
                                    <DatePicker v-model="signtime" @on-change="changeSignTime" type="daterange"
                                        placement="bottom-end" placeholder="点击选择时间" style="width: 250px">
                                    </DatePicker>
                                </div>
                            </div>
                            <div class="r-btn flex wrap">
                                <Button @click="getListSearch" type="primary">查询</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="r-box" v-if="userInfo.pid == 0">
                    <div class="nav_title">合同提醒</div>
                    <div class="nav_switch">
                        <div class="label" style="margin-right: 16px;font-size: 12px">未签署提醒</div>
                        <i-switch @on-change="closeNumber" v-model="overform.remind_status" :true-value="1"
                            :false-value="0" size="large">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>
                    </div>
                    <div class="flex" style="margin-top: 12px;font-size: 12px" v-if="overform.remind_status === 1">
                        <div class="msg-l">到期前</div>
                        <div class="msg-i" style="margin-left: 8px;margin-right: 8px"><Input
                                v-model="overform.contract_reminder_num" type="number" placeholder="请填写天数"
                                style="width: 80px" /></div>
                        <div class="msg-l">天，提醒客户签署</div>
                    </div>
                    <div class="nav_switch">
                        <div class="label" style="margin-right: 16px;font-size: 12px">续签提醒</div>
                        <i-switch @on-change="closeNumber" v-model="overform.reminder_status" :true-value="1"
                            :false-value="0" size="large">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>
                    </div>
                    <div class="flex" style="margin-top: 12px;font-size: 12px" v-if="overform.reminder_status === 1">
                        <div class="msg-l">过期前</div>
                        <div class="msg-i" style="margin-left: 8px;margin-right: 8px"><Input
                                v-model="overform.renew_ones_num" type="number" placeholder="请填写天数"
                                style="width: 80px" /></div>
                        <div class="msg-l">天，提醒客户续签</div>
                    </div>
                    <div class="keep flex-c" @click="changeNumber">保存</div>
                </div>
            </div>
            <div class="table_card">
                <div class="flex">
                    <button :class="selectContractIds.length == 0 ? 'table-btn no-select-ids' : 'table-btn danger'"
                        @click="toDelete()">批量删除</button>
                    <div class="r-find">
                        <Select v-model="storePrams.sort" style="width:150px;margin-left: 12px" @on-change="goGetList">
                            <Option v-for="item in listType" :value="item.value" :key="item.value">{{ item.label }}
                            </Option>
                        </Select>
                    </div>
                    <!-- //功能甚是强大 正式服严禁使用 -->
                    <!-- <button class="table-btn danger"  @click="goZuofeiPage()" >单页数据作废</button>
                    <button class="table-btn danger"  @click="goDeletePage()" >单页数据删除</button> -->
                </div>

                <br />
                <Table :loading="loading" :columns="columns" :data="data | filter_data(userInfo)"
                    @on-selection-change="getSelection">
                    <template slot-scope="{row}" slot="operation">
                        <!-- style="flex-wrap: wrap;gap: 10px;padding: 10px 0;" -->
                        <div class="flex-c">
                            <div class="table-btn blue" @click="xiazai(row)">下载</div>
                            <div class="table-btn del" @click="seeContractDel(row)">详情</div>
                            <div class="table-btn blue" v-if="row.status_ === 0" @click="changeMsg(row)"> 未签提醒</div>
                            <!-- 非CA 未签署 开启短信通知 -->
                            <div class="table-btn blue"
                                v-if="$store.state.identity != 2 && row.status_ == 0 && row.sms_notice == 1"
                                @click="goReshipment(row)">重发短信</div>
                            <!-- 下载存证报告 企业版、 除了未签署的 -->
                            <div class="table-btn blue" v-if="$store.state.identity == 2 && row.status_ != 0"
                                @click="goDepositReport(row)">存证报告</div>
                            <div class="table-btn " style="color:#ffa500;border:1px solid #ffa500"
                                v-if="row.status_ === 1 && !(row.start_expired == '' || row.start_expired == null || row.start_expired == undefined || row.start_expired == '0000-00-00')"
                                @click="changeoverMsg(row)">续签提醒</div>
                            <div class="table-btn danger" @click="toVoid(row)"
                                v-if="(userInfo.id == row.user_id || userInfo.role == 2) && row.status_ !== 3">作废
                            </div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="id">
                        <div style="display: flex;justify-content: center;align-items: center;">
                            <div>{{ row.id }}</div>
                            <Poptip trigger="hover" :content="'该合同还有' + row.revoke_time_days + '天进入回收站'"
                                v-if="row.revoke_time_days">
                                <div class="red_box">{{ row.revoke_time_days }}</div>
                            </Poptip>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        <div v-if="row.status">
                            {{ row.status }}
                            <div v-if="row.status_ == 3">{{ row.updated_at }}</div>
                        </div>
                        <div v-else>{{ row.is_pay }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="due">
                        <div>{{ row.start_time }} 至 {{ row.end_time }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="term">
                        <div
                            v-if="row.start_expired == null || row.start_expired == undefined || row.start_expired == '' || row.start_expired == '0000-00-00'">
                            暂无日期</div>
                        <div v-else>{{ row.start_expired }} 至 {{ row.end_expired }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="sign_time">
                        <div>{{ row.sign_time == 0 ? "未签署" : row.sign_time }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="sms">
                        <div v-if="row.sms_notice == 1">开启</div>
                        <div v-else style="color:#ff0000">未开启</div>
                    </template>
                    <template slot-scope="{ row }" slot="ticket">
                        <div style="display: flex;flex-wrap: wrap;cursor: pointer;align-items: center;justify-content: center"
                            v-if="row.label_arr.length > 0" @click.prevent="changeLabel(row)">
                            <div v-for="(item, index) in row.label_arr" style="margin-left: 10px;color: #1a9aef;"
                                :key="index">
                                <Tag :color="item.color_value ? item.color_value : 'default'"><span>{{ item.name
                                        }}</span></Tag>
                            </div>
                        </div>
                        <div v-else>暂无标签</div>
                    </template>
                </Table>
                <Page :total="storePrams.total" :current="storePrams.page" style="text-align:right;margin-top: 20px"
                    show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit" />
            </div>
        </div>
        <Modal title="未签提醒" v-model="addMsg" :styles="{ top: '20%' }" @on-ok="SubNum" @on-cancel="cancelMsg"
            :mask-closable="false">
            <div class="flex">
                <div class="msg-l" style="margin-left:24px">到期前</div>
                <div class="msg-i" style="margin:0 12px"><Input v-model="contractNum" type="number" placeholder="请填写天数"
                        style="width: 200px" /></div>
                <div class="msg-l">天，提醒客户签署</div>
            </div>
        </Modal>
        <Modal title="续签提醒" v-model="addoverMsg" :styles="{ top: '20%' }" @on-ok="SuboverNum" @on-cancel="canceloverMsg"
            :mask-closable="false">
            <div class="flex">
                <div class="msg-l" style="margin-left:24px">过期前 </div>
                <div class="msg-i" style="margin:0 12px"><Input v-model="contractoverNum" type="number"
                        placeholder="请填写天数" style="width: 200px" /></div>
                <div class="msg-l">天，提醒客户续签</div>
            </div>
        </Modal>
        <Modal title="合同标签" v-model="changeThat" :styles="{ top: '20%' }" @on-ok="SubNumThat" @on-cancel="cancelThat"
            :mask-closable="false">
            <div class="flex">
                <div class="label">合同标签：</div>
                <div class="r-find ">
                    <Select v-model="changeVal.label_id" multiple style="width:360px">
                        <Option v-for="item in ticketList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </div>
            </div>
        </Modal>
        <Modal v-model="recycleModal" :footer-hide="true" class-name="vertical-center-modal" width="800px">
            <div>
                <div class="message-alert message-modal-alert">
                    <div class="alert-title">提示</div>
                    <div class="alert-content" style="padding: 20px;margin-top: 20px;">
                        <p>1、超过<span>30天</span>未签署的合同将会进入<span>合同回收站</span>，可去合同回收站<span>恢复合同</span>。
                            回收站内<span>超过{{ delDay }}天</span>没处理，则会<span>删除</span>该合同。
                        </p>
                        <p>2、从回收站恢复的合同<span>超过7天</span>没签署，则会<span>再次进入回收站</span>，<span>超过{{ delDay
                        }}天</span>没处理，则会<span>删除</span>该合同。
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
        <detail ref="contractDel" @goBack="showSelf"></detail>
    </div>
</template>

<script>
import detail from './components/contractDel'
import {
    contractDetails,
    contractList, editContractReminder, getContractReminder,
    getpdf,
    reminder, renewal,
    searchList,
    updateLabel,
    voidContract,
    deleteContract,
    get_managelist
} from '../../api/interFace'
import config from '@/config/index'

import { sms_motice_sign, get_contract_notice } from '@/api/center'

export default {
    name: "contract",
    components: {
        detail
    },
    data() {
        return {
            fromVal: {
                contract_name: '',
                start_time: '',
                end_time: '',
            },
            selectVal: '',
            timeVal: [],
            signtime: [],
            administratorsList: [],
            listType: [
                {
                    label: '创建日期降序',
                    value: '1'
                },
                {
                    label: '创建日期升序',
                    value: '2'
                },
                {
                    label: '签署日期降序',
                    value: '3'
                },
                {
                    label: '签署日期升序',
                    value: '4'
                }
            ],
            selectList: [
                {
                    label: '未签署',
                    value: '1'
                },
                {
                    label: '未付款',
                    value: '2'
                },
                {
                    label: '已付款',
                    value: '7'
                },
                {
                    label: '已签署',
                    value: '3'
                },
                {
                    label: '已到期',
                    value: '4'
                },
                {
                    label: '已作废',
                    value: '5'
                },
                {
                    label: '即将到期',
                    value: '6'
                },
            ],
            contractNum: '',
            contractoverNum: '',
            columns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '合同ID',
                    key: 'id',
                    slot: 'id',
                    align: 'center',
                    width: 115
                },
                {
                    title: '合同名称',
                    key: 'contract_name',
                    align: 'center',
                    minWidth: 280
                },
                {
                    title: '签约截止日期',
                    align: 'center',
                    slot: 'due',
                    width: 210
                },
                {
                    title: '合同有效日期',
                    align: 'center',
                    slot: 'term',
                    width: 210
                },
                {
                    title: '签署日期',
                    align: 'center',
                    key: 'sign_time',
                    slot: 'sign_time',
                    width: 210
                },
                {
                    title: '创建日期',
                    align: 'center',
                    key: 'create_time',
                    width: 210
                },
                {
                    title: '合同标签',
                    slot: 'ticket',
                    align: 'center',
                    minWidth: 300
                },
                {
                    title: '短信通知',
                    slot: 'sms',
                    align: 'center',
                    width: 100
                },
                {
                    title: '发起者',
                    key: 'initiator',
                    align: 'center',
                    width: 130
                },
                {
                    title: '状态',
                    slot: 'type',
                    align: 'center',
                    width: 180
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 400,
                    align: 'center',
                    fixed: 'right',
                    slot: 'operation'
                }
            ],
            data: [],
            storePrams: {
                limit: 10,
                page: 1,
                total: 0,
                user_id: '',
                user_phone: '',
                end_time: "",
                contract_id: '',
                sign_time: "",
                end_sign_time: '',
                search_part: '',
                create_time: '',
                status: '',
                type: 1,
                sort: '1' //1  创建时间降序   2创建升序   3签署时间降序   4签署 升序
            },
            showDel: true,
            addMsg: false,
            addoverMsg: false,
            loading: false,
            changeThat: false,
            recycleModal: false,
            delDay: 0,
            overform: {
                remind_status: 0,
                contract_reminder_num: 0,
                reminder_status: 0,
                renew_ones_num: 0
            },
            changeVal: {
                label_id: '',
                contract_id: ''
            },
            ticketList: [],
            selectContractIds: [],
            name: this.$route.query
        }
    },
    created() {
        this.$store.commit('changePollingSpin', false)
        //批量合同详情跳转
        if (this.$route.query.id) {
            this.seeContractDel(this.$route.query)
        }
        if (this.userInfo.role == 2) {
            this.get_managelist() //获取列表
        }
        let data = this.$route.query.name
        if (data == 'staysigned_count') {
            this.storePrams.status = "1"
            this.getListSearch()
        }
        if (data == 'signed_count') {
            this.storePrams.status = "3"
            this.getListSearch()
        }
        if (data == 'expired_count') {
            // 即将到期
            this.storePrams.status = "6"
            this.getListSearch()
        }
        if (data == 'monthSigned_count') {
            this.storePrams.status = "3"
            //本月已签署
            let firstDay = this.getFirstDay()
            let endDay = this.getLastDay()
            this.storePrams.create_time = firstDay
            this.storePrams.end_time = endDay
            this.getListSearch()
            this.timeVal[0] = firstDay
            this.timeVal[1] = endDay
        }
        if (data == 'theweek_count') {
            this.storePrams.status = "3"
            this.getWeekDay()
            this.getListSearch()
        }
        this.goGetDelDay()
        this.getList()
        this.getTickets()
        this.getNumber()
        // this.getTS()//获取提示
    },
    mounted() {
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
    },
    filters: {
        filter_data(d, userInfo) {
            let timestamp = new Date().getTime();
            let user_id = userInfo.id;
            d.forEach(function (item) {
                let day = (timestamp - new Date(item.updated_at).getTime()) / 86400000;  //15天
                item._disabled = (item.status_ == 3 && (item.user_id == user_id || userInfo.role == 2) && day > 15) ? false : true
                // item._disabled =false
            })
            return d
        }
    },
    methods: {
        goGetDelDay() {
            get_contract_notice({ type: 4 }).then(res => {
                this.delDay = res.data.mature_days
            }).catch(res => {
            })
        },
        clearAdministrators() {
            this.storePrams.user_phone = ''
        },
        chooseAdministrators(e) {
            if (e == undefined) {
                this.storePrams.user_phone = ''
            }
            this.administratorsList.forEach(item => {
                if (item.id == e) {
                    this.storePrams.user_phone = item.phone
                }
            })
        },
        getTS() {
            this.$Modal.confirm({
                title: '提示',
                content: '您有1份合同已29日未签署， 即将进入合同回收站！',
                width: '450px',
                onOk: () => {
                    // deleteContract({ ids: this.selectContractIds }).then(res => {
                    //     this.$Message.success(res.msg)
                    //     this.getList()
                    // }).catch(res => {
                    //     this.$Message.error(res.msg)
                    //     this.getList()
                    // })
                },
            });

        },
        getWeekDay() {
            var now = new Date();
            var nowTime = now.getTime();
            var day = now.getDay();
            var oneDayTime = 24 * 60 * 60 * 1000;
            //显示周一
            var MondayTime = nowTime - (day - 1) * oneDayTime;
            //显示周日
            var SundayTime = nowTime + (7 - day) * oneDayTime;
            //初始化日期时间
            var monday = new Date(MondayTime);
            var sunday = new Date(SundayTime);
            function add0(m) { return m < 10 ? '0' + m : m }
            function format(shijianchuo) {
                //shijianchuo是整数，否则要parseInt转换
                var time = new Date(shijianchuo);
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                return y + '-' + add0(m) + '-' + add0(d);
            }
            this.storePrams.create_time = format(monday)
            this.storePrams.end_time = format(sunday)
            this.timeVal[0] = format(monday)
            this.timeVal[1] = format(sunday)

        },
        changeTime(e) {
            console.log(e)
            this.storePrams.create_time = e[0]
            this.storePrams.end_time = e[1]
        },
        changeSignTime(e) {
            this.storePrams.sign_time = e[0]
            this.storePrams.end_sign_time = e[1]
        },
        getSelection(e) {
            this.selectContractIds = []
            let _this = this
            e.forEach(function (item) {
                _this.selectContractIds.push(item.id)
            })
        },
        goDeletePage() {
            //功能甚是强大 正式服严禁使用
            // let arr = []
            // this.data.forEach(item=>{
            //     arr.push(item.id)
            // })
            // deleteContract({ids:arr}).then(res=>{
            //     this.$Message.success(res.msg)
            //     this.getList()
            // }).catch(res => {
            //     this.$Message.error(res.msg)
            //     this.getList()
            // })

        },
        toDelete() {
            if (this.selectContractIds.length == 0) {
                this.$Message.error("请选择合同"); return;
            }
            this.$Modal.confirm({
                title: '删除提示',
                content: '请问您是否确认删除选择的合同！！！',
                onOk: () => {
                    deleteContract({ ids: this.selectContractIds }).then(res => {
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res => {
                        this.$Message.error(res.msg)
                        this.getList()
                    })
                },
                onCancel: () => {
                }
            });
        },
        getFirstDay() { //当前月第一天
            var y = new Date().getFullYear(); //获取年份
            var m = new Date().getMonth() + 1; //获取月份
            var d = '01';
            m = m < 10 ? '0' + m : m; //月份补 0            
            return [y, m, d].join("-")
        },
        getLastDay() { //当前月最后一天
            var y = new Date().getFullYear(); //获取年份
            var m = new Date().getMonth() + 1; //获取月份
            var d = new Date(y, m, 0).getDate(); //获取当月最后一日
            m = m < 10 ? '0' + m : m; //月份补 0
            d = d < 10 ? '0' + d : d; //日数补 0        
            return [y, m, d].join("-")
        },
        goGetList() {
            this.getListSearch()
        },
        getListSearch() {
            this.storePrams.page = 1
            this.getList()
        },
        //获取管理员列表
        get_managelist() {
            get_managelist({ title: '' }).then(async res => {
                this.administratorsList = res.data
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        getList() {
            this.loading = true
            contractList(this.storePrams).then(async res => {
                this.data = res.data.data || []
                this.storePrams.total = res.data.total
                this.loading = false
            }).catch(res => {
                this.loading = false
                this.$Message.error(res.msg)
            })
        },
        closeNumber(e) {
            console.log(e)
            // if(e === 0) {
            //     editContractReminder({status:0,day_num:this.overTime_num}).then(res => {
            //         this.$Message.success(res.msg)
            //     }).catch(res => {
            //         this.$Message.error(res.msg)
            //     })
            // }
        },
        getNumber() {
            getContractReminder().then(res => {
                this.overform.remind_status = Number(res.data.is_contract_reminder)
                this.overform.reminder_status = Number(res.data.is_renew_ones)
                this.overform.renew_ones_num = res.data.renew_ones_num
                this.overform.contract_reminder_num = res.data.contract_reminder_num
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        changeNumber() {
            editContractReminder(this.overform).then(res => {
                this.$Message.success(res.msg)
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        goZuofeiPage() {
            //功能甚是强大 正式服严禁使用
            // this.data.forEach(item=>{
            //     voidContract({id:item.id}).then(async res => {
            //         // this.getList()
            //         this.$Message.success(res.msg)
            //     }).catch(res => {
            //         this.$Message.error(res.msg)
            //     })
            // })
        },
        toVoid(row) {
            this.$Modal.confirm({
                title: '作废提示',
                content: '请问您是否确认作废此合同！！！',
                onOk: () => {
                    voidContract({ id: row.id }).then(async res => {
                        this.getList()
                        this.$Message.success(res.msg)
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        getTickets() {
            searchList().then(res => {
                this.ticketList = res.data || []
            }).catch(res => {
                console.log(res)
            })
        },
        changeLabel(row) {
            this.changeVal.contract_id = row.id
            let arr = []
            row.label_arr.forEach(item => {
                arr.push(item.id)
            })
            this.changeVal.label_id = arr
            this.changeThat = true
        },
        SubNumThat() {
            updateLabel(this.changeVal).then(res => {
                this.$Message.success(res.msg)
                this.getList()
                this.changeThat = false
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        cancelThat() {
            this.changeThat = false
        },
        changeMsg(row) {
            this.contractId = row.id
            this.contractNum = row.not_signed_num
            this.addMsg = true
        },
        goDepositReport(row) {
            this.$Modal.confirm({
                title: '确认操作',
                content: '<p>是否确认下载存证报告？</p>',
                onOk: () => {
                    let url = config.apiUrl + '/adminapi/contract/download_deposit_report?contract_id=' + row.contract_id + '&uniacid_id=' + this.userInfo.uniacid_id
                    console.log('url', url)
                    window.open(url)
                },
                okText: '确定',
                // 不显示取消按钮 
                cancelText: ''
            });
        },
        goReshipment(row) {
            let id = row.id
            this.$Modal.confirm({
                title: '确认操作',
                content: '<p>是否确认该合同重发短信？</p>',
                onOk: () => {
                    // 确认操作
                    sms_motice_sign({ contract_id: id }).then(async res => {
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                okText: '确定',
                // 不显示取消按钮 
                cancelText: ''
            });

        },
        cancelMsg() {
            this.addMsg = false
            this.contractNum = ''
        },
        SubNum() {
            reminder({ contract_id: this.contractId, days: this.contractNum }).then(async res => {
                this.$Message.success(res.msg)
                this.addMsg = false
                this.getList()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        changeoverMsg(row) {
            this.contractId = row.id
            this.contractoverNum = row.renewal_num
            this.addoverMsg = true
        },
        canceloverMsg() {
            this.addoverMsg = false
            this.contractoverNum = ''
        },
        SuboverNum() {
            renewal({ contract_id: this.contractId, days: this.contractoverNum }).then(async res => {
                this.$Message.success(res.msg)
                this.addoverMsg = false
                this.getList()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        time() {
            var date = new Date(this.storePrams.create_time)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            this.storePrams.create_time = time
            return time
        },
        pageChange(index) {
            this.storePrams.page = index
            this.getList()
        },
        seeContractDel(row) {
            const msg = this.$Message.loading({
                content: 'Loading...',
                duration: 0
            });
            contractDetails({ id: row.id, pcStatus: 1 }).then(res => {
                this.showDel = false
                this.$nextTick(() => {
                    this.$refs.contractDel.showDetail(res)
                })
                setTimeout(msg, 100);
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        xiazai(e) {
            if (e.contract_path === '') {
                getpdf({ id: e.id }).then(res => {
                    window.open(res.data)
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            } else {
                window.open(e.contract_path)
            }
        },
        showSelf() {
            this.showDel = true
        }
    }
}
</script>

<style scoped lang="scss">
.page-wrap {
    .top-card {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .l-box {
            background: #FFFFFF;
            width: 75%;
            padding: 20px 20px;
            border-radius: 8px;
        }

        .r-box {
            width: 24%;
            background: #FFFFFF;
            padding: 15px 20px 5px 20px;
            box-sizing: border-box;
            border-radius: 8px;

            .nav_switch {
                display: flex;
                align-items: center;
                margin-top: 12px;
            }

            .keep {
                width: 78px;
                height: 30px;
                background: #4877E8;
                border-radius: 3px;
                font-size: 12px;
                font-weight: bold;
                color: #FFFFFF;
                cursor: pointer;
                margin-right: 20px;
                margin-top: 8px;
            }
        }

        .input-group {
            .f-wrap {
                flex-wrap: wrap;
            }

            .wrap {
                margin-top: 20px;
                margin-right: 25px;
            }

            .label {
                font-size: 14px;
                color: #545F78;

                .iconfont {
                    color: #4877E8;
                    font-size: 18px;
                }
            }

            .r-btn {
                .d-btn {
                    width: 78px;
                    height: 30px;
                    background: #F77F54;
                    border-radius: 15px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #FFFFFF;
                    cursor: pointer;

                    &:hover {
                        opacity: .4;
                    }
                }
            }
        }
    }

    .table_card {
        width: 100%;
        min-height: 600px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px 20px;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .modal-pop {
        .msg-add {
            width: 500px;
            background: white;
            border-radius: 8px;
            padding: 40px 30px 20px 30px;
            box-sizing: border-box;

            .msg-l {
                font-size: 14px;
                color: #333333;
            }

            .msg-i {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .cancel {
        width: 100px;
        height: 34px;
        color: #1B2742;
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-right: 20px;

        &:hover {
            color: #4877E8;
        }
    }
}

.red_box {
    border: 1px solid #FF0000;
    color: #FF0000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: 5px;
}

.table-btn {
    padding: 5px 10px !important;
    font-size: 14px !important;
}

.ivu-table-wrapper {
    width: 100%;
    overflow: auto !important;
}

.no-select-ids {
    background: #FFFFFF;
    border: 1px solid #e9e9e9;
    color: #e9e9e9;
}

/* 消息提示容器 */
.message-alert {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding: 18px;
    margin: 12px auto;
    margin-top: 0px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.message-alert-back {
    background-image: linear-gradient(to right, #1544af, #1479f5);
}


.message-modal-alert {
    background-image: url('../../assets/images/huishouzhan.png');
    background-size: cover;
    width: 800px;
    height: 324px;
}

/* 标题样式 */
.alert-title {
    width: 100px;
    border-radius: 6px;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.4);
}

.alert-content {
    padding: 0 10px;
}

/* 内容区域 */
.alert-content p {
    margin: 12px 0;
    // line-height: 1.2;
    font-size: 16px;
    opacity: 0.95;
}

.alert-content span {
    color: #FFFC00;
    font-size: 20px;
}


/deep/ .vertical-center-modal .ivu-modal-body {
    padding: 0px !important;
}

/deep/ .vertical-center-modal .ivu-modal-close .ivu-icon-ios-close {
    color: #fff
}

/deep/ .vertical-center-modal .ivu-modal-content {
    border-radius: 12px;
}
</style>
